import React from "react"
import { Helmet } from "react-helmet"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

import Layout from "../../components/layout"
import ConfianEnNosotros from "../../components/confian-en-nosotros"

import bgComercio from "../../assets/old/img/cotizador/bg-comercio.jpg"

export default () => (
  <Layout menuActivo="cotizar-seguros">
    <Helmet>
      <title>Cotiza tu seguro de Comercio</title>
      <meta
        name="description"
        content="Cotiza con nosotros tu seguro de comercio y obtén beneficios en tus nuevas pólizas."
      />
    </Helmet>

    <section
      className="bg-w pt-3 pb-5"
      style={{ backgroundImage: `url(${bgComercio})` }}
    >
      <div className="container text-center pt-5 pb-5 mb-5">
        <div className="row pb-5 mb-5">
          <div className="col-md-12 mb-30">
            <h1 className="display-4 text-white">
              Compará coberturas online<span className="text-primary">!!</span>
            </h1>
            <h2
              className="h3 block-title text-center mt-2"
              style={{ color: "#FFF" }}
            >
              y ahorrá hasta un 30% en tu seguro de vivienda y comercio
            </h2>
          </div>
        </div>
      </div>
    </section>

    <section className="container pb-3" style={{ marginTop: -220 }}>
      <div className="bg-white2 box-shadow px-3 px-sm-5 pt-2 pb-5 text-center text-sm-left">
        <Formik
          initialValues={{
            superficie: "",
            sumaAsegurada: "",
            nombre: "",
            celular: "",
            email: "",
          }}
          validationSchema={() =>
            Yup.object().shape({
              superficie: Yup.number()
                .integer("Debe ser un número entero")
                .required("Requerido"),
              sumaAsegurada: Yup.number()
                .integer("Debe ser un número entero")
                .required("Requerido"),
              nombre: Yup.string().required("Requerido"),
              celular: Yup.string()
                .matches(
                  /\d{10,13}/,
                  "Ingrese sólo números sin espacios, sin el 0 y sin el 15"
                )
                .required("Requerido"),
              email: Yup.string()
                .email("Debe ser un email válido")
                .required("Requerido"),
            })
          }
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            let solicitud = ""
            solicitud += `Superficie: ${values.superficie}\n`
            solicitud += `Suma asegurada: ${values.sumaAsegurada}\n`
            const data = {
              otro_producto_id:
                process.env.WOKAN_WEBPACK_OTRO_PRODUCTO_COMERCIO_ID,
              nombre: values.nombre,
              email: values.email,
              telefono: values.celular,
              solicitud,
            }
            fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/otros/cotizacion`, {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
                "Content-Type": "application/json",
              },
            })
              .then(response => {
                if (!response.ok) {
                  alert("Ocurrió un error, intente nuevamente por favor.")
                } else {
                  resetForm()
                  setStatus({ enviado: true })
                }
                setSubmitting(false)
              })
              .catch(err => {
                alert(
                  "¿Está conectado a internet? Ocurrió un error y no se puede procesar su solicitud, intente nuevamente más tarde por favor."
                )
                setSubmitting(false)
              })
          }}
        >
          {({ isSubmitting, errors, touched, status, isValid }) => (
            <Form className="pt-4">
              {status && status.enviado && (
                <div className="alert alert-success text-center">
                  <i className="fe-icon-check-square" />
                  &nbsp;&nbsp;<strong>¡Enviado!</strong> Pronto un asesor le
                  enviará su cotización.
                </div>
              )}
              <div className="row">

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>
                      Superficie M2 <strong className="text-danger">*</strong>
                    </label>
                    <Field
                      name="superficie"
                      type="number"
                      className={`form-control ${errors.superficie &&
                        touched.superficie &&
                        " is-invalid"}`}
                    />
                    <ErrorMessage
                      name="superficie"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>
                      Suma asegurada por robo
                      <strong className="text-danger">*</strong>
                    </label>
                    <Field
                      name="sumaAsegurada"
                      type="number"
                      className={`form-control ${errors.sumaAsegurada &&
                        touched.sumaAsegurada &&
                        " is-invalid"}`}
                    />
                    <ErrorMessage
                      name="sumaAsegurada"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>
                      Nombre <strong className="text-danger">*</strong>
                    </label>
                    <Field
                      name="nombre"
                      type="text"
                      className={`form-control ${errors.nombre &&
                        touched.nombre &&
                        " is-invalid"}`}
                    />
                    <ErrorMessage
                      name="nombre"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>
                      Celular (con código de área)
                      <strong className="text-danger">*</strong>
                    </label>
                    <Field
                      name="celular"
                      type="text"
                      placeholder="Ej: 1175845239"
                      className={`form-control ${errors.celular &&
                        touched.celular &&
                        " is-invalid"}`}
                    />
                    <ErrorMessage
                      name="celular"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>
                      Email <strong className="text-danger">*</strong>
                    </label>
                    <Field
                      name="email"
                      type="email"
                      className={`form-control ${errors.email &&
                        touched.email &&
                        " is-invalid"}`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className="btn btn-gradient"
                >
                  {isSubmitting ? 'UN SEGUNDO...' : 'ENVIAR'}
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <ConfianEnNosotros />
      </div>
    </section>
  </Layout>
)
